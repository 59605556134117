.App {
  height: 100vh;
}

.profile-link {
  width: 50px;
  height: 50px;
  margin: 20px;
}

a {
  text-decoration: none;
}

.container {
  text-align: center;
  height: 100%;
}
