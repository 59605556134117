.header {
  height: 100vh;
  transition: height 1s ease;

  &.done-loading {
    height: 40px;

    .text-inner {
      font-size: 30px;
      transform: translate(-50vw) translateX(calc(50% + 10px));
    }
  }
}
