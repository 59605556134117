@import "../../scss/variables.scss";

.blinking {
  color: $red;
  animation: 1000ms infinite blink;
}

.loading-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 3px 10px;
  transition: all 1s ease;
}

.text-inner {
  font-size: 10vw;
  transition: all 1s ease;
  /* done loading effect in Header.scss*/
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
